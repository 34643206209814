
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class UserList extends Vue {
  @Prop({
    required: true
  })
  public userList!: any;

  public UpdateFollow(v, id, i) {
    const payload = {
      user_id: id,
      status: ''
    };
    if (v) {
      payload.status = 'stop';
      this.$q.notify('팔로우를 취소하셨습니다.');
      this.userList.follow_state = !this.userList.follow_state
    } else {
      payload.status = 'live';
      this.$q.notify('팔로우 하셨습니다.');
      this.userList.follow_state = !this.userList.follow_state
    }

    // userModule.UpdateFollowUser(payload).then((res) => {
    //   this.userList[i].follow_state = !this.userList[i].follow_state;
    // });
  }
}
