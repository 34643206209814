


















/**
 * ToDo
 * 1. 새로고침 후 swipe 상태 수정
 * 2. 이미지 없을 경우
 */
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import { EventBus } from '@/config/config';

@Component
export default class ThumbnailList extends Vue {
  @Prop()
  public thumbnail!: any[];
  @Prop()
  public defaultImg!: any;
  @Prop()
  public paginationType!: any;
  @Prop()
  public itemIndex!: any;
  @Prop()
  public thumbItem!: any;

  public swiper: any = null;
  public loading: boolean = false;

  public mounted() {
    this.activeSwiper();
    EventBus.$on('thumbnailListReset', () => {
      this.loading = false;
      setTimeout(() => {
        this.loading = true;
        this.activeSwiper();
      }, 700);
    });
    this.loading = true;
  }
  public updated() {
    this.activeSwiper();
  }

  public thumbnailDetail(index: any, thumbItem?: any) {
    EventBus.$emit('thumbnailIndex', index);
    EventBus.$emit('thumbnailList', this.thumbnail, thumbItem);
    this.$modal.show('thumbnailDetail');
  }
  public activeSwiper() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    let el: any = '.detail-thumbnail-container ';
    if (this.paginationType === 'bull') {
      el = `.bull.detail-thumbnail-container.item-${this.itemIndex}`;
    }
    const pageType = this.paginationType === 'bull' ? 'bullets' : 'fraction';
    const swiper = new Swiper(`${el} .swiper-container`, {
      slidesPerView: 'auto',
      loop: true,
      observer: true,
      observeParents: true,
      pagination: {
        el: `${el} .swiper-pagination.item-${this.itemIndex}`,
        type: pageType
      },
    });
  }
}
