






























































































































































































import Component, { mixins } from 'vue-class-component';
import { reviewModule } from '@/store';
import { auth } from '@/shared/auth';
import ListMixins, { InfiniteHandlerState } from '@/components/list/ListMixins';
import ThumbnailList from '@/components/common/ThumbnailList.vue';
import ReviewSlideBar from '@/components/review/ReviewSlideBar.vue';
import UserList from '@/components/list/UserList.vue';
import DataFormat from '@/shared/utils/dataFormat';
import ThumbnailDetailModal from '@/pages/modal/ThumbnailDetailModal.vue';
import ThumbnailListModal from '@/pages/modal/ThumbnailListModal.vue';
import { EventBus } from '@/config/config';

@Component({
  components: { ThumbnailListModal, ThumbnailDetailModal, ThumbnailList, ReviewSlideBar, UserList }
})
export default class DetailReview extends mixins(ListMixins, DataFormat) {
  public reviewModal: boolean = false;
  public commentModal: boolean = false;
  public modalType: string = 'review';
  public infoState: boolean = false;
  public commentContents: string = '';
  public replyHeight: number = 40;
  public selectComment: any = null;
  public updateState: boolean = false;
  public confirmMessage: string = '';
  public params = {
    review_id: 1,
    offset: 1,
    limit: 10
  };
  public loadingStatus: boolean = false;

  public beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadingStart();
      vm.params.review_id = to.params.id;
      reviewModule.fetchReviewComment(vm.params).then(() => {
        vm.loadingEnd();
      });
    });
  }

  public beforeRouteUpdate(to, from, next) {
    this.params.review_id = to.params.id;
    next();
  }

  public beforeDestroy() {
    this.loadingEnd();
  }
  public loadingStart() {
    EventBus.$emit('loadingStatus', true);
    this.loadingStatus = true;
  }
  public loadingEnd() {
    EventBus.$emit('loadingStatus', false);
    this.loadingStatus = false;
  }

  public inputHeight() {
    const textarea = document.querySelector('textarea' as any);
    textarea.addEventListener('input', () => {
      this.replyHeight = textarea.scrollHeight;
    });
  }

  public $_listMixins_fetch($state: InfiniteHandlerState) {
    reviewModule
      .fetchReviewComment(this.params)
      .then(() => {
        if (this.comment && this.comment.data.length >= this.comment.meta.count) {
          $state.complete();
        } else {
          $state.loaded();
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  get userId() {
    return Number(auth.getAccessUserId());
  }

  get review(): any {
    return reviewModule.getDetailReview;
  }

  get comment() {
    return reviewModule.getComment;
  }

  get pastScore() {
    if (this.review.food_stars && this.review.service_stars && this.review.mood_stars) return false;
    else return true;
  }

  public created() {
    reviewModule.fetchDetailReview(this.$route.params.id);
  }

  public SlideState() {
    this.infoState = false;
    this.reviewModal = false;
    this.commentModal = false;
  }

  // 유저 팔로우
  // public UpdateFollow(v, id) {
  //   const payload = {
  //     user_id: id,
  //     status: ''
  //   };
  //   if (v) {
  //     payload.status = 'stop';
  //     this.$q.notify('팔로우를 취소하셨습니다.');
  //     this.review.follow_state = !this.review.follow_state
  //   } else {
  //     payload.status = 'live';
  //     this.$q.notify('팔로우 하셨습니다.');
  //     this.review.follow_state = !this.review.follow_state
  //   }

  //   // userModule.UpdateFollowUser(payload).then((res: boolean | undefined) => {
  //   //   if (res) reviewModule.fetchDetailReview(this.$route.params.id);
  //   // });
  // }

  // 리뷰 좋아요
  // public likeReview(id, state) {
  //   if (!this.isLogin) {
  //     this.$q.notify('로그인이 필요합니다.');
  //     this.$modal.show('login');
  //   } else {
  //     const payload = {
  //       target: 'review',
  //       target_id: id
  //     };
  //     if (!state) {
  //       this.$q.notify('리뷰를 좋아요 하였습니다.');
  //       likeModule.fetchLike(payload).then((res) => {
  //         reviewModule.fetchDetailReview(id);
  //       });
  //     } else {
  //       this.$q.notify('좋아요를 취소하였습니다.');
  //       likeModule.fetchLikeDelete(payload).then((res) => {
  //         reviewModule.fetchDetailReview(id);
  //       });
  //     }
  //   }
  // }

  // get isLogin() {
  //   return authModule.isLogin;
  // }

  public starScoreAvg(f, s, m) {
    const score = (f / 20) + (s / 20) + (m / 20);
    return (score / 3).toFixed(1);
  }

  // public loginCheck() {
  //   if (!this.isLogin) {
  //     this.$q.notify('로그인이 필요합니다.');
  //     this.$modal.show('login');
  //   }
  // }

  // 댓글 등록
  public addComment(id) {
    if (!this.updateState) {
      const commentData = {
        review_id: id,
        contents: this.commentContents
      };
      reviewModule.addComment(commentData).finally(() => {
        this.commentContents = '';
        reviewModule.fetchReviewComment(this.params);
      })
    } else {
      const id = this.selectComment.id;
      const payload = {
        contents: this.commentContents
      }
      reviewModule.updateComment({ id, payload }).then((res: any) => {
        if (res.status) {
          this.$q.notify('댓글을 수정했습니다.');
          reviewModule.fetchReviewComment(this.params);
        } else {
          this.$q.notify(res.error.message);
        }
        this.updateState = false;
        this.commentContents = '';
      })
    }
  }

  // 리뷰 삭제 confirm창 열기
  public openModal(type) {
    this.reviewModal = false;
    this.commentModal = false;

    this.modalType = type;
    if (type === 'review') this.confirmMessage = '리뷰를 삭제하시겠습니까?';
    else this.confirmMessage = '댓글을 삭제하시겠습니까?';

    this.$modal.show('delete');
  }

  // 리뷰 삭제
  public deleteReview(id) {
    reviewModule.deleteReview(id).then((res) => {
      if (res.status) {
        this.$q.notify('리뷰가 삭제 되었습니다.');
        this.$router.go(-1);
        // this.$router.push({ path: `/user/${this.userId}/review` });
      } else {
        this.$q.notify(res.error.message);
      }
    });
  }

  // 댓글 수정
  public changeComment() {
    this.updateState = true;
    this.commentContents = this.selectComment.contents;
    this.commentModal = !this.commentModal;
    const focusItem: any = document.getElementById('commentText');
    focusItem.focus();
  }

  // 댓글 삭제
  public deleteComment() {
    reviewModule.deleteComment(this.selectComment.id).then((res: any) => {
      if (res.status) {
        this.$q.notify('댓글이 삭제 되었습니다.');
        this.commentModal = false;
        this.$modal.hide('delete');
        reviewModule.fetchReviewComment(this.params);
      }
    });
  }

  // 댓글 수정 취소
  public setState() {
    setTimeout(() => {
      if (this.updateState) {
        this.updateState = false;
        this.commentContents = '';
      }
    }, 1);
  }

  public moreModal(type, comment) {
    if (type === 'review') this.reviewModal = !this.reviewModal;
    else this.commentModal = !this.commentModal;

    if (comment) this.selectComment = comment;
  }
}
